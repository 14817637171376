import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {
    apiNewListChildren
} from "../../../../request/api.js";
export default {
    components: {
        Header,
        Footer,
    }, //添加注册组件事件
    data() {
        return {
            list: [],
            title: '',
            plateId: '',
            pageSize: 20,
            page: 1,
            buttonShow: 1,
            plateImage: '',
            plateSubTit: '',
            plateTitle: '',
            nodata: false,
        };
    },
    created() {
        let plateId = unescape(this.$route.query.plateId);
        let title = unescape(this.$route.query.title);
        this.title = title;
        this.plateId = plateId;
        this.newListChildren()
    },
    mounted() {},
    methods: {
        // 获取新闻列表
        newListChildren() {
            apiNewListChildren({
                plateId: this.plateId,
                pageSize: this.pageSize,
                page: this.page
            }).then(res => {
                this.plateImage = res.data.data.plateImage
                this.plateSubTit = res.data.data.plateSubTit
                this.plateTitle = res.data.data.plateTitle
                let arr = res.data.data.list.data
                this.list = this.list.concat(arr)
                if (this.list.length >= res.data.data.list.total) {
                    this.buttonShow = 2
                }
                if (res.data.data.list.data.length == 0) {
                    this.nodata = true
                }
            });
        },
        // 获取新闻列表

        loadFun() {
            this.page++
                this.newListChildren()
        },

        LinkDetails(id) {
            window.location.href = this.FunPublic.url + "newDetails.html?" + "id=" + escape(id) + "&title=" + escape(this.title) + "&plateId=" + escape(this.plateId) + "&typemodel=0"
        }
    },
};